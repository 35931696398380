import React,{useState,useEffect} from 'react';
import {Route,Redirect} from 'react-router-dom'
import DashboardPages from '../DashboardPages';
import {useDispatch, useSelector} from 'react-redux'
import loginCheck from '../../../redux/actions/login';
import Axios from 'axios'
const Login = (props) => {
    
    if(localStorage.getItem('authInfo')&&props.location.pathname==='/login'){
        props.history.push('/dashboard') 
    }
   
    
    const dispatch=useDispatch()
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [checkbox, setCheckbox] = useState();
   
    const submitHandaler=async(e)=>{
        e.preventDefault()
        const response=await Axios.post('http://localhost:2000/api/user/dashboardLogin',{email,password})
        .catch(err=>console.log(err))
        if(response){
            console.log(response)
        const info=response.data.token
        localStorage.setItem('authInfo',info)
        props.history.push('/dashboard')
        dispatch(loginCheck(response.data.msg))
        }
    }

    
    return (
        <div style={{background:'#f0f0f1',width:'100%',height:'100vh',position:'absolute'}}>
        <div className="col-4 m-auto mt-5">
        {/* <h3>Login To Your Dashboard</h3> */}
            <form className="shadow bg-white fs-4 p-5">
                
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input 
                    placeholder="Enter your email.."
                    type="email" 
                    name="email"
                    value={email}

                    onChange={(e)=>{setEmail(e.target.value)}}
                    className="form-control fs-6" 
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp" />
                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input 
                    placeholder="Enter your password..."
                    name="password"
                    value={password}
                    type="password"
                    onChange={(e)=>{setPassword(e.target.value)}}
                     className="form-control fs-6"
                      id="exampleInputPassword1"
                       />
                </div>
                <div className="mb-3 form-check">
                    <input 
                    name="checkbox"
                    type="checkbox" 
                    className="form-check-input fs-4"
                    onChange={(e)=>{setCheckbox(e.target.checked)}}
                     id="exampleCheck1" 
                     />
                    <label className="form-check-label fs-6" htmlFor="exampleCheck1">Check me out</label>
                </div>
                <button 
                type="submit" 
                className="btn btn-primary fs-6"
                onClick={submitHandaler}
                >Login</button>
            </form>

        </div>
        </div>
    );
}

export default Login;
