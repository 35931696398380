import React, { useEffect } from "react";
import asmaul from "../About/AboutImages/asmaul.jpg";
import main_p from "../Images/asmaul-signle1.png";

import Axios from "axios";
const Home = () => {
  // useEffect(()=>{
  //    async function getData(){
  //         try{
  //             let {data}=await Axios.get('http://127.0.0.1:8000/api/allstudents')
  //             console.log(data)
  //         }catch(error){
  //             console.log(error)
  //         }
  //     }
  //     getData()
  // },[])
  return (
    <div id="home" className="home-container mt-5 pt-3 px-5">
      <div className="row justify-content-between pb-4 pt-5">
        <div
          className="col-12 col-md-4 col-lg-3 justify-content-center inner-shadow main-photo p-0 m-0 small-device"
          style={{ height: "fit-content" }}
        >
          <div className="text-center m-0 p-0">
            <img
              width="100%"
              style={{
                filter: " brightness(1.1)",
                borderBottomLeftRadius: " 138px",
                // transform: "scale(1.1)",
              }}
              src={main_p}
              alt="asmaul"
            />
          </div>
        </div>
        <div className="col-12 text-md-start text-lg-start col-sm-11 col-md-7 col-lg-7">
          <p
            className="welcome"
            style={{ color: "#ff014f", fontFamily: '"Montserrat", sans-serif' }}
          >
            WELCOME TO MY WORLD
          </p>
          <h1 style={{ color: "#0d6efd" }}>
            Hi, I'm{" "}
            <span
              style={{
                color: "#ff014f",
                fontFamily: '"Montserrat", sans-serif',
              }}
            >
              Asmaul
            </span>
          </h1>
          <h6 style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Full Stack developer
          </h6>
          <p
            className="mt-3"
            style={{ fontSize: "20px", fontFamily: "Montserrat, sans-serif" }}
          >
            I am a professional full stack web developer with a passion for
            creating beautiful and functional websites. I have been working with
            web technologies for over last 5 years. I have worked on a wide
            range of personal projects. I am currently working as a full stack
            developer in individual companies on project basis.
          </p>

          <div className="mt-5 pt-2">
            <h6 style={{ fontFamily: "Montserrat, sans-serif" }}>Find me in</h6>
            <ul
              className="home-icon d-sm-flex d-flex pt-sm-3 pb-3"
              style={{ fontSize: "25px" }}
            >
              <li
                className="inner-shadow"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Instagram"
              >
                <a
                  className="mt-2"
                  href="https://www.instagram.com/asmaul_sarder/"
                  target="_blank"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li className="inner-shadow">
                <a
                  className="mt-2"
                  href="https://github.com/asmaulsarder"
                  target="_blank"
                >
                  <i className="fa fa-github"></i>
                </a>
              </li>
              <li className="inner-shadow">
                <a
                  className="mt-2"
                  href="https://www.facebook.com/mdasmaulsarder12345/"
                  target="_blank"
                >
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li className="inner-shadow">
                <a
                  className="mt-2"
                  href="https://www.linkedin.com/in/asmaul-sarder-305765318/"
                  target="_blank"
                >
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="large-device col-12 col-md-5 col-lg-5 col-xl-3 justify-content-center inner-shadow main-photo p-0 m-0"
          style={{ height: "fit-content" }}
        >
          <div className="text-center m-0 p-0">
            <img
              width="100%"
              style={{
                filter: " brightness(1.1)",
                borderBottomLeftRadius: " 138px",
                // transform: "scale(1.1)",
              }}
              src={main_p}
              alt="asmaul"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
