import React, { useEffect, useRef, useState } from "react";
import "./SkillStyle.css";
import backend from "./skillImages/backend.png";
import frontend from "./skillImages/web-development.png";
import api from "./skillImages/api.png";
import nodelogo from "./skillImages/nodejs.png";

const Skill = () => {
  return (
    <div className="skillMaincontainer" id="skill">
      <div className="section-title">
        <h2 style={{ fontFamily: '"Montserrat", sans-serif' }}>My Skills</h2>
      </div>

      <div
        data-aos="fade-up"
        className="row skills justify-content-center hover-scale-1 p-4 container m-auto"
      >
        <div className="col-11 col-sm-11 col-md-10 col-lg-5">
          <h4>Backend Development</h4>
          <div className="row mb-5">
            <div className="row align-items-center mt-3">
              <div className="col-3">NodeJs</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "70%" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">80%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">Laravel</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "60%", background: "violet" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">80%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">MongoDB</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "65%", background: "#42a902" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">75%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">MySql</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "75%", background: "#740b0b" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">80%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">Socket Programming</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "75%", background: "#740b0b" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">70%</div>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-sm-0 col-md-0"></div>

        <div className="col-11 col-sm-11 col-md-10 col-lg-5">
          <h4>Frontend Development</h4>
          <div className="row">
            <div className="row align-items-center mt-3">
              <div className="col-3">Bootstrap</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "55%", background: "blueviolet" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">95%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">Javascript</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "80%", background: "#e5df1d" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">90%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">Jquery</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%", background: "rgb(229 41 171)" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">80%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">ReactJS</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "75%", background: "blue" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">95%</div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-3">NextJs</div>
              <div className="col">
                <div className="progress" style={{ height: "0.3rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "60%", background: "#0dcaf0" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-1">80%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
