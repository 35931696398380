import React, { useState } from "react";
import About from "../components/About/About";

import RightSide from "../components/RightSidebar/RightSide";
import Navbar from "../components/navbar/index";

import Skill from "../components/Skills/Skill";
import Contuct from "../components/contuct/Contuct";

import Footer from "../components/footer/Footer";
import Home from "../components/Home/Home";
import Educations from "../components/Education/Educations";
import Portfolio from "../components/Portfolio/Portfolio";
import Services from "../components/Services/Services";
const Page = () => {
  //     const [isOpen, setIsOpen] = useState(false);
  //     const toggle=()=>{
  //       setIsOpen(!isOpen)
  //     }
  //     const [leftSidebarOpen, setleftSidebarOpen] = useState(false);

  //     const leftSideToggle=()=>{
  //         setleftSidebarOpen(!leftSidebarOpen)
  //     }
  //    const [isScroll, setisScroll] = useState(false);

  //     window.addEventListener('scroll',()=>{
  //        window.scrollY>0?setisScroll(true):setisScroll(false)

  //     })

  return (
    <div className="" style={{ backgroundColor: "#ECF0F3" }}>
      <Navbar />
      <RightSide />
      <Home />
      <About />
      <Portfolio />
      <Skill />
      <Educations />
      {/* <Services /> */}
      <Contuct />
      <Footer />
    </div>
  );
};

export default Page;
