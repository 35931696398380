import React from 'react';
import {FaArrowAltCircleRight,FaTimes}from 'react-icons/fa'
import './Style.css'
import {Link} from 'react-router-dom'
const RightSide = ({leftSidebarOpen,leftSideToggle}) => {
    
    return (
        <div onClick={leftSideToggle} className="mainContainer">
            <div className="icon" onClick={leftSideToggle}>
            {leftSidebarOpen?"":<FaArrowAltCircleRight className="iconLinks"/>}
            </div>
            <div style={leftSidebarOpen?{left:'0%'}:{left:'-30%'}} className="leftSideWrap">
                <div onClick={leftSideToggle} className="close">
                    <FaTimes/>
                </div>
                <div className="leftSideMenu">
                    <ul>
                        <li>About</li>
                        <li>Services</li>
                        <li>Descover</li>
                        <li>Sign In</li>
                    </ul>
                </div>
                <div className="leftSideBtn">
                    <Link className="leftSideBtnLink" to="/signup">Sign Up</Link>
                </div>
            </div>

        </div>
    );
}

export default RightSide;
