import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Page from './pages/Page'

import DashboardPages from './components/dashboard/DashboardPages'
import Login from "./components/dashboard/login/Login";
import DashboardProtectedRoute from "./components/dashboard/dashboardprotectedroute/DashboardProtectedRoute";

function App() {

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Page} />
          <Route path="/login" component={Login} ></Route>
          <DashboardProtectedRoute path="/dashboard" component={DashboardPages} />
          <Route>404 Not Found</Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
