
import { Types } from '../actions/constant';

const initialState={
    id:{},
    login:false
}

export const getParamIdReducer=(state=initialState,action)=>{
    switch(action.type){
        case Types.PARAM_ID:{
            return{
                ...state,
                id:action.id
            }
        }
        default :return state
        }
}

export const loginReducer=(state=initialState,action)=>{
    switch(action.type){
        case Types.IS_LOGIN:{
            return{
                ...state,
                login:action.login
            }
        }
        default :return state
        }
}

