import React, { useEffect } from "react";
const Portfolio = () => {
  const data = [
    {
      id: 1,
      name: "Bangladesh Tax Calculation (Quick Tax BD)",
      image: "assets/img/portfolio/tax-software.png",
      galary: [],
    },
    {
      id: 2,
      name: "It Management Web Application Development",
      image: "assets/img/portfolio/mukhait.jpg",
      galary: [],
    },
    {
      id: 3,
      name: "Cabin Crew Management System (Biman Bangladesh Airlines)",
      image: "assets/img/portfolio/cabin-crew.jpg",
      galary: [],
    },

    {
      id: 4,
      name: "Multi Vendor Ecommerce Website Development",
      image: "assets/img/portfolio/mowchak.jpg",
      galary: [],
    },
    {
      id: 5,
      name: "Single Vendor Ecommerce Website With POS System",
      image: "assets/img/portfolio/dejuzpark.jpg",
      galary: [],
    },
    {
      id: 6,
      name: "Inventory Management System With POS Support",
      image: "assets/img/portfolio/dejuzpark-dashboard.jpg",
      galary: [],
    },

    {
      id: 7,
      name: "Pension Management System (Biman Bangladesh Airlines)",
      image: "assets/img/portfolio/biman-pension.jpg",
      galary: [],
    },
    {
      id: 8,
      name: "Property(Square wise) Tenant Management System",
      image: "assets/img/portfolio/property-management-system.png",
      galary: [],
    },
    {
      id: 9,
      name: "Accessories Management System for Factories",
      image: "assets/img/portfolio/agq-accessories.jpg",
      galary: [],
    },
  ];
  return (
    <div id="portfolio">
      <section id="portfolio" className="portfolio">
        <div className="home-container">
          <div className="section-title text-center mb-3">
            <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>
              My Portfolio
            </h1>
            <p
              className="secondary text-uppercase"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "14px",
                letterSpacing: "1.3px",
              }}
            >
              Welcome to my portfolio. Visit all my portfolios and keep your
              feedback
            </p>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters" className="inner-shadow main-photo row">
                <li
                  data-filter="*"
                  className="filter-active col-sm-12 col-md-4 col-lg-3"
                >
                  All
                </li>
                <li
                  data-filter=".filter-app"
                  className="col-sm-12 col-md-4 col-lg-3"
                >
                  App
                </li>
                <li
                  data-filter=".filter-card"
                  className="col-sm-12 col-md-4 col-lg-3"
                >
                  Card
                </li>
                <li
                  data-filter=".filter-web"
                  className="col-sm-12 col-md-4 col-lg-3"
                >
                  Web
                </li>
              </ul>
            </div>
          </div>

          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-1.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="App 1"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div> */}

            {data.map((item, index) => (
              <div
                className="col-lg-4 col-md-6 portfolio-item filter-web"
                key={index}
              >
                <div className="portfolio-wrap inner-shadow main-photo p-4">
                  <div className="d-flex align-items-center w-100 h-100 justify-content-center position-absolute">
                    <h5 className="portfolio-title">{item.name}</h5>
                  </div>

                  <img
                    style={{
                      width: 500,
                      height: "300px !important",
                      borderRadius: "10px",
                    }}
                    src={item.image}
                    className="img-fluid"
                    alt=""
                  />

                  <div className="portfolio-links">
                    <a
                      target="_blank"
                      href={item.image}
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Web 3"
                    >
                      <i className="bx bx-plus"></i>
                    </a>
                    <a href="portfolio-details.html" title="More Details">
                      <i className="bx bx-link"></i>
                    </a>
                  </div>
                  <h5
                    className="mt-4"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      lineHeight: "33px",
                    }}
                  >
                    {item.name}
                  </h5>
                </div>
              </div>
            ))}

            {/* <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <div className="d-flex align-items-center w-100 h-100 justify-content-center position-absolute">
                  <h5 className="portfolio-title">
                    Bangladesh Biman Pension Management
                  </h5>
                </div>
                <img
                  src="assets/img/portfolio/portfolio-3.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-3.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="App 2"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-4.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-4.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Card 2"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-5.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-5.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Web 2"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-6.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-6.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="App 3"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-7.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-7.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Card 1"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-8.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-8.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Card 3"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/portfolio-9.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/portfolio-9.jpg"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Web 3"
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <a href="portfolio-details.html" title="More Details">
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
