import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./AboutStyle.css";

const About = () => {
  const [_aboutRef, setAboutRef] = useState(null);
  const [_aboutImgRef, setAboutImgRef] = useState(null);
  let aboutInfos = useRef(null);
  let aboutImg = useRef(null);

  useEffect(() => {
    setAboutRef(aboutInfos);
    setAboutImgRef(aboutImg);
  }, []);

  const datas = [
    {
      id: 1,
      title: "Custom Web Development",
      short_description:
        "Design and develop custom websites tailored to your specific business needs using technologies like Laravel, React.js, Next.js, or Node.js. Build from scratch or update existing websites to enhance functionality and user experience.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/web-development.png",
    },
    {
      id: 1,
      title: "Front-End Development",
      short_description:
        "Develop visually appealing and interactive front-end interfaces using HTML, CSS, CSS framework such as Bootstrap and Tailwind, JavaScript, React, or Next.js.Ensure responsive design and cross-browser compatibility for optimal performance across devices",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/front-end.png",
    },
    {
      id: 1,
      title: "Back-End Development",
      short_description:
        "Develop secure, efficient, and scalable back-end systems using Laravel, Node.js, or other frameworks. Set up server-side logic, APIs, and databases to manage dynamic content and user interactions.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/back-end.png",
    },

    {
      id: 1,
      title: "Full-Stack Web Development",
      short_description:
        "Offer complete end-to-end solutions by handling both the front-end and back-end development of web applications. Create fully integrated systems that are scalable, secure, and optimized for performance.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/full-stack.png",
    },

    {
      id: 1,
      title: "E-commerce Development",
      short_description:
        "Build and customize e-commerce platforms with shopping cart integration, payment gateways (e.g., Stripe, PayPal), and secure user authentication. Implement features like product catalogs, inventory management, order tracking, and customer reviews",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/ecommerce.png",
    },
    {
      id: 1,
      title: "API Development & Integration",
      short_description:
        "Develop custom APIs or integrate third-party APIs to enhance your website or application functionality. Implement RESTful APIs for communication between your web app and external services or mobile apps.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/api-integration.png",
    },

    {
      id: 1,
      title: "Content Management Systems (CMS)",
      short_description:
        "Build or customize CMS platforms (custom Laravel-based CMS) for clients who need to manage and update their own website content. Provide user-friendly dashboards and tools for content updates without needing technical expertise.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/cms.png",
    },

    {
      id: 1,
      title: "Website Maintenance & Support",
      short_description:
        "Offer ongoing support, updates, and maintenance for websites to ensure they are secure, up-to-date, and running smoothly. Perform regular backups, performance monitoring, and bug fixes.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/maintainance-and-support.png",
    },

    {
      id: 1,
      title: "Performance Optimization & SEO",
      short_description:
        "Improve website performance with optimization techniques such as image compression, caching, and code minification. Implement basic on-site SEO (Search Engine Optimization) to help improve search engine rankings and increase traffic.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/optimization.png",
    },

    {
      id: 1,
      title: "Database Design & Management",
      short_description:
        "Design and manage databases (MySQL, PostgreSQL, MongoDB) to ensure efficient data storage, retrieval, and management. Implement secure database connections, backups, and scaling solutions.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/database.png",
    },

    {
      id: 1,
      title: "Security Audits & Enhancements",
      short_description:
        "Conduct security audits on your website or application to identify vulnerabilities. Implement security measures such as SSL encryption, two-factor authentication, and protection against threats like SQL injection, XSS, and CSRF.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/security.png",
    },

    {
      id: 1,
      title: "Consulting & Technical Guidance",
      short_description:
        "Provide consultation services to help clients plan their projects, select the right technologies, and define a clear development strategy. Offer advice on web technologies, frameworks, and best practices for optimal results.",
      long_description:
        "Node.js is an open-source, server-side runtime environment that allows developers to run JavaScript on the server. Built on Chrome's V8 JavaScript engine, it is known for its non-blocking, event-driven architecture, making it ideal for building fast, scalable network applications like APIs and real-time web apps. Node.js excels in handling multiple requests simultaneously and is widely used for applications requiring high concurrency, such as chat applications, data streaming, and microservices. Its package ecosystem, npm, is one of the largest open-source libraries available.",
      img: "/assets/img/services/consulting.png",
    },
  ];

  function limit(text, count) {
    return text.slice(0, count) + (text.length > count ? "" : "");
  }

  return (
    <div id="features" className="home-container">
      <div style={{ color: "white", width: "100px" }} id="test"></div>
      <div className="section-title">
        <p
          style={{ fontFamily: "Montserrat, sans-serif" }}
          className="secondary mb-3"
        >
          FEATURES
        </p>
        <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>What I Do</h1>
      </div>

      <div className="aboutDetails " data-aos="fade-up">
        <div className="row justify-content-center" data-aos="fade-up">
          {datas?.map((item, index) => (
            <div className="col-12 col-md-5 col-lg-5 col-xl-4" key={index}>
              <div className="p-4 inner-shadow main-photo mx-2 mb-5 custom-card w-100">
                <div className="custom-card-margin p-0 text-center">
                  <img
                    width={80 + "%"}
                    height={250}
                    src={item.img}
                    style={{ margin: "auto" }}
                  />
                </div>
                <Link to="">
                  <h4
                    className="my-3"
                    style={{ fontFamily: "Montserrat, sans-serif" }}
                  >
                    {item.title}
                  </h4>
                  <div
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      lineHeight: "30px",
                    }}
                  >
                    {item.short_description}
                  </div>
                  <div className="bottom-icon-wrapar">
                    <i class="bx bx-right-arrow-alt bottom-icon"></i>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
