import React from "react";
import { LuGraduationCap } from "react-icons/lu";
import { LuClock3 } from "react-icons/lu";
const Educations = () => {
  return (
    <div className="container mt-5" id="education">
      <div className="section-title text-left">
        <h2>My Educations</h2>
      </div>

      <div className="row justify-content-center mt-2" data-aos="fade-up">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
          <div className="inner-shadow main-photo p-2 py-3">
            <h5 className="mb-3">
              <LuGraduationCap style={{ fontSize: "38px", color: "#0d6efd" }} />{" "}
              Primary and Higher Certificate
            </h5>
            <div style={{ marginLeft: "35px" }}>
              <h6 style={{ color: "green" }}>
                Kalia Pilot High School, Kalia, Narail
              </h6>
              <h6>
                <LuClock3 /> 2009 to 2017
              </h6>
              <h6>GPA: 4.93 out of 5.00</h6>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
          <div className="inner-shadow main-photo p-2 py-3">
            <h5 className="mb-3">
              <LuGraduationCap style={{ fontSize: "38px", color: "#0d6efd" }} />{" "}
              Diploma In Computer Science
            </h5>
            <div style={{ marginLeft: "35px" }}>
              <h6 style={{ color: "green" }}>
                Khulna Polytechnic Institute, Khulna
              </h6>
              <h6>
                <LuClock3 /> 2017 to 2021
              </h6>
              <h6>CGPA: 3.31 out of 4.00</h6>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
          <div className="inner-shadow main-photo p-2 py-3">
            <h5 className="mb-3">
              <LuGraduationCap style={{ fontSize: "38px", color: "#0d6efd" }} />{" "}
              Bachelor of Science in Computer{" "}
            </h5>
            <div style={{ marginLeft: "35px" }}>
              <h6 style={{ color: "green" }}>
                Dhaka International University, Dhaka
              </h6>
              <h6>
                <LuClock3 /> 2022 to 2026
              </h6>
              <h6>
                CGPA: <LuClock3 />
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Educations;
