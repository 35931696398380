import React from "react";
import {Redirect,Route} from 'react-router-dom'
const DashboardProtectedRoute = ({component:Component,...rest}) => {
    console.log('hello')
        const getAuth=localStorage.getItem('authInfo')
        return (
        <Route {...rest} render={(props)=>(
            getAuth?<Component {...props}/>:<Redirect to='/login'/>
        )}/>
    );
}

export default DashboardProtectedRoute;
