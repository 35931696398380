import React from 'react';
import './style.css'
const AllSkills = () => {
    return (
        <div className="allSkillMainContainer">
            <div className="addNew">
                <h2>All Skills</h2>
            </div>
            <div className="postNumberAndSearch">
                    <div className="postNumber">
                    <h6>All(3)</h6> | <h6>Published(3)</h6> |<h6>Draft(0)</h6>
                    </div>
                    <div className="postSearch">
                    <input />
                     <button>Search Skill</button>
                    </div>
            </div>
            <table class="table table-striped table-inverse table-responsive">
                <thead class="thead-inverse">
                    
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                   
                    <tbody>
                        <tr>
                            <td>no image</td>
                            <td>Asmaul sarder</td>
                            <td>Asmaul</td>
                            <td>02-04-2021</td>
                        </tr>
                        <tr>
                        <td>no image</td>
                            <td>Robin kahan</td>
                            <td>Asmaul</td>
                            <td>10-06-2020</td>
                        </tr>
                    </tbody>
            </table>
            <div className="selectAndApply">
               <div className="selectOption">
               <select>
                    <option>Bulk Action</option>
                    <option>Edit</option>
                    <option>Move To Trush</option>
                </select>
               </div>
               <div className="apply">
                    <button>Apply</button>
               </div>
            </div>
        </div>
    );
}

export default AllSkills;
