import React,{useEffect,useState} from 'react';
import './contuctStyle.css'
const Contuct = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");



    const submitHandler=(e)=>{
        e.preventDefault()
        const data={
            name,
            email,
            subject,
            message
        }
        console.log(data)
    }
    return (
        <div className="contuctMaincontainer" id="contuct">
            <div className="section-title">
          <h2>Contact Me</h2>
          </div>
    
            <div className="row justify-content-center p-0 m-0" data-aos="fade-up">
            <div className="align-items-center text-center col-10 col-sm-10 col-md-8 col-lg-4">
            <iframe className=''  style={{width:'100%',height:'80%'}} title="kalia" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117498.7042543738!2d89.58367883264877!3d23.029847831393724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ffbe72b6b351ff%3A0x2c0fa11aeb43258!2sKalia%20Upazila!5e0!3m2!1sen!2sbd!4v1625380683535!5m2!1sen!2sbd" loading="lazy"></iframe>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-3 justify-content-center align-items-center'>
                <div className='p-5 row justify-content-center text-center'>
                    <div className='col'>
                        <div className='row pt-2 justify-content-center'>
                            <h5><i className="fa fa-phone text-success" aria-hidden="true"></i> Call Me</h5>
                            <p>+8801754938653</p>
                        </div>
                    </div>

                    <div className='col'>
                        <div className='row pt-2'>
                            <h5><i className="fa fa-envelope text-success" aria-hidden="true"> </i> Email</h5>
                            <p>mdasmaulsarder123@gmail.com</p>
                        </div>
                    </div>
                    
                    <div className='col'>
                    <div className='row pt-2'>
                        <h5><i className="fa fa-whatsapp text-success" aria-hidden="true"></i> Whatsapp</h5>
                        <p>+8801754938653</p>
                    </div>
                    </div>
                    
                </div>
                   
            </div>
            <div className="contuct-form p-sm-3 col-10 col-sm-10 col-md-10 col-lg-4">
                <div className='row'>
                    <div className='col'>
                    <div className="mb-3">
                    <label htmlFor="Title" className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="firstname"
                        name="firstname"
                        placeholder='Name'
                        onChange={(e)=>setName(e.target.value)}
                    />
                    </div>
                    </div>
                    <div className='col'>
                    <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="text"
                        className="form-control form-control-sm"
                        id="email"
                        name="email"
                        placeholder='Email'
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="subject" className="form-label">Subject</label>
                    <input type="text"
                        className="form-control form-control-sm"
                        id="subject"
                        name="subject"
                        placeholder='Subject'
                        onChange={(e)=>setSubject(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea type="text"
                    rows="5"
                        className="form-control form-control-sm h-sm-10"
                        id="message"
                        name="message"
                        placeholder='Message'
                        onChange={(e)=>setMessage(e.target.value)}
                    />
                </div>
                <button onClick={submitHandler} type="button" className="btn btn-success">Submit</button>

            </div>
            </div>
           
        </div>
    );
}

export default Contuct;
